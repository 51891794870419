<template>
  <b-card class="card-congratulation-medal">
    <h4 class="mb-2">{{ $t("quickly_menu") }}</h4>
    <b-button variant="gradient-success" block :to="{ name: 'add-employee' }">
      <feather-icon icon="UserIcon" class="mr-50" />
      <span class="align-middle">{{ $t("add_employee") }}</span>
    </b-button>

    <b-button
      variant="gradient-danger"
      block
      :to="{ name: 'admin-evaluation-main' }"
    >
      <feather-icon icon="HeartIcon" class="mr-50" />
      <span class="align-middle">{{ $t("evaluation") }}</span>
    </b-button>

    <b-button variant="gradient-warning" block :to="{ name: 'admin-setting' }">
      <feather-icon icon="SettingsIcon" class="mr-50" />
      <span class="align-middle">{{ $t("setting") }}</span>
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BCardText, BLink, BButton, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
};
</script>
