<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="12" md="12">
        <home-statistics />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col lg="8">
        <home-employee-table />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col lg="4" md="6">
        <home-detail />
      </b-col>
      <!--/ Developer Meetup Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import HomeStatistics from "./HomeStatistics.vue";
import HomeEmployeeTable from "./HomeEmployeeTable.vue";
import HomeDetail from "./HomeDetail.vue";
import HomeMenu from "./HomeMenu.vue";
export default {
  components: {
    BRow,
    BCol,

    HomeStatistics,
    HomeEmployeeTable,
    HomeDetail,
    HomeMenu,
  },
  setup() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
