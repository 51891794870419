<template>
  <b-card v-if="statistics" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>{{ $t("statistics") }}</b-card-title>
      <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in statistics"
          :key="index"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import { onMounted, ref } from "@vue/composition-api";
import { adminService } from "@/services/admin";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  setup() {
    const statistics = ref([
      {
        icon: "UserIcon",
        color: "light-primary",
        title: "230k",
        subtitle: "จำนวนบริษัท",
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "UserIcon",
        color: "light-info",
        title: "8.549k",
        subtitle: "พนักงานทั้งหมด",
        customClass: "mb-2 mb-xl-0",
      },
      {
        icon: "UserIcon",
        color: "light-danger",
        title: "1.423k",
        subtitle: "รายการรออนุมัติ",
        customClass: "mb-2 mb-sm-0",
      },
      {
        icon: "UserIcon",
        color: "light-success",
        title: "$9745",
        subtitle: "จำนวนพนักงานปกติ",
        customClass: "",
      },
    ]);

    onMounted(() => {
      getStatic();
    });

    const getStatic = async () => {
      try {
        const result = await adminService.getHomeStatic();

        if (result.status) {
          statistics.value[0].title = result.data.totalCompany;
          statistics.value[1].title = result.data.totalEmployee;
          statistics.value[2].title = result.data.totalPendingEmployee;
          statistics.value[3].title = result.data.totalNormalEmployee;
        }
      } catch (e) {
        console.log(e);
      }
    };
    return {
      statistics,
    };
  },
};
</script>
