<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- company -->
      <template #cell(company)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            size="32"
            :src="data.item.logo"
            :variant="`light-primary`"
          />
          <div class="ml-1">
            <div class="font-weight-bolder">
              {{ data.item.name }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.mobileNo }}
            </div>
          </div>
        </div>
      </template>

      <!-- category -->
      <template #cell(status)="data">
        <span
          style="margin-left: 30px"
          class="bullet bullet-sm"
          :class="`bullet-${data.item.commercialFile ? 'success' : 'danger'}`"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BAvatar, BImg } from "bootstrap-vue";
import { onMounted, ref } from "@vue/composition-api";
import { companyService } from "@/services/company";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
  setup() {
    const fields = [
      { key: "company", label: "บริษัท" },
      { key: "totalEmployees", label: "จำนวนพนักงาน" },
      { key: "email", label: "อีเมล" },
      { key: "status", label: "สถานะเอกสาร" },
    ];

    const tableData = ref(null);

    onMounted(() => {
      getCompanyAdmin();
    });

    const getCompanyAdmin = async () => {
      try {
        const result = await companyService.getCompanyAdmin();
        if (result.status) {
          tableData.value = result.data;
        }
      } catch (e) {
        console.log(e);
      }
    };
    return {
      fields,
      tableData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
